import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import "../styles/index.css"

import Post from "../components/post";
import Layout from "../components/layout";
import Pagination from "../components/pagination";

export default function Index({ data, pageContext }) {
  const { currentPage, pageCount } = pageContext;

  const posts_data = data.allNotion.nodes;
  // const siteInfo = data.strapiAbout;

  const triggerBuild = (e) => {
    e.preventDefault();
    fetch('https://api.netlify.com/build_hooks/63633d626e14f915f2046c27', {
      method: 'POST'
    }).then(res => {
      console.log('res', res);
      alert("Deploy Successful!")
    })
    .catch(res => {
      alert("Error Deploying!")
    });
  }

  const posts = posts_data.map(item => {
    return (
      <Link to={`/${item.id}`}>
        <Post item={item}></Post>
      </Link>
    )
  });

  return (
    <Layout siteInfo={data.site.siteMetadata}>
      { posts }
      <a
        className="refresh_btn"
        href="#"
        onClick={triggerBuild}
      >刷新</a>
      <Pagination
        currentPage={currentPage}
        pageCount={pageCount}
      />
    </Layout>
  )
}

export const Head = ({data}) => {
  const site = data.site.siteMetadata;
  return (
    <>
      <title>{ site.title }</title>
    </>
  )
}

export const query = graphql`
query PageQuery($skip: Int!, $limit: Int!) {
  site {
    siteMetadata {
      description
      title
    }
  }
  allNotion(
    skip: $skip,
    sort: {order: DESC, fields: createdAt},
    limit: $limit
  ) {
    nodes {
      id
      title
      createdAt(formatString: "YYYY-MM-DD HH:MM")
      raw {
        icon {
          emoji
        }
      }
      childMarkdownRemark {
        html
        excerpt(format: HTML)
      }
    }
  }
}
`
